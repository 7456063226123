import "./Shifokorlar.css";
import { Link } from "react-router-dom";

import medic1 from "../../assets/images/leder5.jpg";
import medic2 from "../../assets/images/leder6.jpg";
import medic3 from "../../assets/images/leder7.jpg";
import medic4 from "../../assets/images/leder8.jpg";

const Shifokorlar = ({ language }) => {
  return (
    <div className="container">
      <div className="medic">
        <h1 className="medic_title">{language === "uz" ? "Shifokorlar" : "Врачи"}</h1>
        <div className="medic_content">
          <div className="medic_card">
            <img className="medic_images" src={medic1} alt="medic" />
            <div className="medic_card_content">
              <p className="leder_card_text">
                Ташкилий-услубий бўлим мудири Файзиева Гулнора Ёшузоқовна
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="medic_card">
            <img className="medic_images" src={medic2} alt="medic" />
            <div className="medic_card_content">
              <p className="leder_card_text">
                Атажанов Шерзод Яхшибоевич - бирлашманинг компьютер техникаси
                хизмати бўйича муҳандиси
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="medic_card">
            <img className="medic_images" src={medic3} alt="medic" />
            <div className="medic_card_content">
              <p className="leder_card_text">
                Бирлашманинг Касаба уюшма қўмитаси раиси Айжариқов Эшмумин
                Абдиназарович
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="medic_card">
            <img className="medic_images" src={medic4} alt="medic" />
            <div className="medic_card_content">
              <p className="leder_card_text">
                Умумий ва иқтисодий масалалар бўйича ўринбосар Норжигитов
                Баходир Холбоевич
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shifokorlar;
