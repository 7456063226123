import { Route } from "react-router-dom";
import { useState, useEffect } from "react";

import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { Overlay } from "../components/utils";
import Footer from "../components/footer/Footer";
import Home from "./home/Home";
import Shifokorlar from "./shifokorlar/Shifokorlar";
import Bulimlar from "./bulimlar/Bulimlar";
import Yangiliklar from "./yangiliklar/Yangiliklar";
import Ish from "./ish/Ish";
import Qvp from "./qvp/Qvp";
import Aloqa from "./aloqa/Aloqa";

const Routes = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "uz"
  );
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    if (sidebar === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebar]);

  return (
    <>
      <Header
        language={language}
        setLanguage={setLanguage}
        setSidebar={setSidebar}
      />
      <Sidebar sidebar={sidebar} setSidebar={setSidebar} language={language} />
      {sidebar && <Overlay setSidebar={setSidebar} />}

      <Route exact path="/">
        <Home language={language} />
      </Route>
      <Route path="/leaders">
        <Shifokorlar language={language} />
      </Route>
      <Route path="/departments">
        <Bulimlar language={language} />
      </Route>
      <Route path="/news">
        <Yangiliklar language={language} />
      </Route>
      <Route path="/vacancy">
        <Ish language={language} />
      </Route>
      <Route path="/qvp">
        <Qvp language={language} />
      </Route>
      <Route path="/contact">
        <Aloqa language={language} />
      </Route>
      <Footer language={language} />
    </>
  );
};

export default Routes;
