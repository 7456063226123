import "./Sidebar.css";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { FaClinicMedical } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { BsSignIntersectionFill } from "react-icons/bs";
import { FaHackerNewsSquare } from "react-icons/fa";
import { FaBriefcaseMedical } from "react-icons/fa6";
import { FaHouseMedicalFlag } from "react-icons/fa6";
import { FaBlenderPhone } from "react-icons/fa";
import headerData from "../../data/header.json";
import sidebarLogo from "../../assets/images/logo.jpg"
const Sidebar = ({ sidebar, setSidebar, language }) => {
  return (
    <div className={sidebar === true ? `sidebar active` : `sidebar`}>
      <div className="sidebar_header">
        <Link to="/">
          <img className="sidebar_logo" src={sidebarLogo} alt="logo" />
        </Link>
        <button className="sidebar_close_icons">
          <IoMdClose onClick={() => setSidebar(false)} />
        </button>
      </div>
      
      <nav className="sidebar_item">
        <Link
          onClick={() => setSidebar(false)}
          to="/"
          className="sidebar_item_list"
        >
          <FaClinicMedical className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.home : headerData.ru.home}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/leaders"
          className="sidebar_item_list"
        >
          <FaUserDoctor className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.doctors : headerData.ru.doctors}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/departments"
          className="sidebar_item_list"
        >
          <BsSignIntersectionFill className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.sections : headerData.ru.sections}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/news"
          className="sidebar_item_list"
        >
          <FaHackerNewsSquare className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.news : headerData.ru.news}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/vacancy"
          className="sidebar_item_list"
        >
          <FaBriefcaseMedical className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.vacancy : headerData.ru.vacancy}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/qvp"
          className="sidebar_item_list"
        >
          <FaHouseMedicalFlag className="sidebar_list_icon"/>
          {language === "uz" ? headerData.uz.qvp : headerData.ru.qvp}
        </Link>
        <Link
          onClick={() => setSidebar(false)}
          to="/contact"
          className="sidebar_item_list"
        >
          <FaBlenderPhone className="sidebar_list_icon"/>
          {language === "uz"
            ? headerData.uz.communication
            : headerData.ru.communication}
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
