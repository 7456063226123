import "./Header.css";
import { Link } from "react-router-dom";
import headerData from "../../data/header.json";
import Logo from "../../assets/images/logo.jpg"
import { TiArrowSortedDown } from "react-icons/ti";
import { CgMenuGridR } from "react-icons/cg";
import { useState } from "react";
const Header = ({ language, setLanguage, setSidebar }) => {
  const [active, setActive] = useState(false);

  function uzFunc(data) {
    localStorage.removeItem("language");
    localStorage.setItem("language", data);
    setLanguage(localStorage.getItem("language"));
    setActive(false);
  }
  function ruFunc(data) {
    localStorage.removeItem("language");
    localStorage.setItem("language", data);
    setLanguage(localStorage.getItem("language"));
    setActive(false);
  }

  return (
    <div className="container">
      <header className="header">
        <Link to="/">
          <img className="header_logo" src={Logo} alt="logo" />
        </Link>
        <nav className="navbar">
          <Link to="/" className="navbar_list">
            {language === "uz" ? headerData.uz.home : headerData.ru.home}
          </Link>
          <Link to="/leaders" className="navbar_list">
            {language === "uz" ? headerData.uz.doctors : headerData.ru.doctors}
          </Link>
          <Link to="/departments" className="navbar_list">
            {language === "uz"
              ? headerData.uz.sections
              : headerData.ru.sections}
          </Link>
          <Link to="/news" className="navbar_list">
            {language === "uz" ? headerData.uz.news : headerData.ru.news}
          </Link>
          <Link to="/vacancy" className="navbar_list">
            {language === "uz" ? headerData.uz.vacancy : headerData.ru.vacancy}
          </Link>
          <Link to="/qvp" className="navbar_list">
            {language === "uz" ? headerData.uz.qvp : headerData.ru.qvp}
          </Link>
          <Link to="/contact" className="navbar_list">
            {language === "uz"
              ? headerData.uz.communication
              : headerData.ru.communication}
          </Link>
        </nav>
        <div className="language_content">
          <p onClick={() => setActive(true)} className="language_value">
            {language}
            <TiArrowSortedDown />
          </p>

          {active ? (
            <div className="language_all">
              <button className="language_btn" onClick={() => uzFunc("uz")}>
                Uz
              </button>
              <button className="language_btn" onClick={() => ruFunc("ru")}>
                Ру
              </button>
            </div>
          ) : null}
        </div>

        <CgMenuGridR
          onClick={() => setSidebar(true)}
          className="header_menu_active"
        />
      </header>
    </div>
  );
};

export default Header;
