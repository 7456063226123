import "./Links.css";
import linksData from "../../data/links.json";
import linksImages1 from "../../assets/images/link1.jpg";
import linksImages2 from "../../assets/images/link2.png";
import linksImages3 from "../../assets/images/link3.png";
import linksImages4 from "../../assets/images/link4.jpg";
const Links = ({ language }) => {
  return (
    <div className="container">
      <div className="links">
        <div className="links_top">
          <h2 className="links_title">
            {language === "uz" ? linksData.uz.title : linksData.ru.title}
          </h2>
          <p className="links_line"></p>
        </div>
        <div className="links_content">
          <a href="https://president.uz/oz" className="links_card">
            <div className="links_images_div">
              <img className="links_images" src={linksImages1} alt="images" />
            </div>
            <p className="links_hover_div">
              {language === "uz" ? linksData.uz.site1 : linksData.ru.site1}
            </p>
          </a>
          <a href="https://gov.uz/ssv" className="links_card">
            <div className="links_images_div">
              <img className="links_images" src={linksImages2} alt="images" />
            </div>
            <p className="links_hover_div">
              {language === "uz" ? linksData.uz.site2 : linksData.ru.site2}
            </p>
          </a>
          <a href="https://edu.uz/uz#gsc.tab=0" className="links_card">
            <div className="links_images_div">
              <img className="links_images" src={linksImages3} alt="images" />
            </div>
            <p className="links_hover_div">
              {language === "uz" ? linksData.uz.site3 : linksData.ru.site3}
            </p>
          </a>
          <a href="https://gov.uz" className="links_card">
            <div className="links_images_div">
              <img className="links_images" src={linksImages4} alt="images" />
            </div>
            <p className="links_hover_div">
              {language === "uz" ? linksData.uz.site4 : linksData.ru.site4}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Links;
