import "./Leadership.css";
import { Link } from "react-router-dom";
import leaderData from "../../data/leader.json";
import Lider1 from "../../assets/images/leder1.jpg";
import Lider2 from "../../assets/images/leder2.jpg";
import Lider3 from "../../assets/images/leder3.jpg";
import Lider4 from "../../assets/images/leder4.jpg";
import Lider5 from "../../assets/images/leder5.jpg";
import Lider6 from "../../assets/images/leder6.jpg";
import Lider7 from "../../assets/images/leder7.jpg";
import Lider8 from "../../assets/images/leder8.jpg";
const Leadership = ({ language }) => {
  return (
    <div className="container">
      <div className="leader">
        <div className="leader_top">
          <h2 className="leader_title">
            {language === "uz" ? leaderData.uz.title : leaderData.ru.title}
          </h2>
          <p className="leader_top_line"></p>
        </div>

        <div className="leader_content">
          <div className="leader_card">
            <img className="leder_card_images" src={Lider1} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Иштихон туман тиббиёт бирлашмаси бошлиғи Худайбердиев Азиз
                Абдуганиевич
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider2} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Кадрлар бўлими бошлиғи Аҳмедов Рахим
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider3} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Даволаш ишлари бўйича ўринбосар Эгамов Отамурод
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider4} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Бирлашма бош фельдшери Жуманазаров Мурадқабул
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider5} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Ташкилий-услубий бўлим мудири Файзиева Гулнора Ёшузоқовна
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider6} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Атажанов Шерзод Яхшибоевич - бирлашманинг компьютер техникаси
                хизмати бўйича муҳандиси
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider7} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Бирлашманинг Касаба уюшма қўмитаси раиси Айжариқов Эшмумин
                Абдиназарович
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="leader_card">
            <img className="leder_card_images" src={Lider8} alt="card images" />
            <div className="leder_card_hover">
              <p className="leder_card_text">
                Умумий ва иқтисодий масалалар бўйича ўринбосар Норжигитов
                Баходир Холбоевич
              </p>
              <Link className="leder_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
