import "./Main.css";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import mainData from "../../data/main.json";
import mainImages1 from "../../assets/images/main_card1.jpg";
import mainImages2 from "../../assets/images/main_card2.jpg";

const Main = ({ language }) => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <main className="main">
      <div className="container">
        <div className="main_content">
          <div className="main_text_content">
            <h2 className="main_title_text">
              {language === "uz" ? mainData.uz.district : mainData.ru.district}
            </h2>
            <h1 className="main_title">
              {language === "uz" ? mainData.uz.union : mainData.ru.union}
            </h1>
            <p className="main_text">
              {language === "uz" ? mainData.uz.region : mainData.ru.region}
            </p>
            <p className="main_media_line"></p>
          </div>

          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="main_caroucel"
          >
            <SwiperSlide>
              <img className="main_images" src={mainImages1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="main_images" src={mainImages2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="main_images" src={mainImages1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="main_images" src={mainImages2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="main_images" src={mainImages1} alt="" />
            </SwiperSlide>
            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span ref={progressContent}></span>
            </div>
          </Swiper>
        </div>
      </div>
    </main>
  );
};

export default Main;
