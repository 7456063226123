import "./Footer.css";
import { Link } from "react-router-dom";
import { FaLocationDot, FaGlobe } from "react-icons/fa6";
import { FaTelegram, FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import footerData from "../../data/footer.json";
import footerLocationImages from "../../assets/images/footer_location_img.png";
import footerLogo from "../../assets/images/footer_logo.png";
const Footer = ({ language }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer_bg">
      <div className="container">
        <footer className="footer">
          <div className="footer_content">
            <div className="footer_card">
              <Link to="/">
                <img src={footerLogo} alt="logo" className="footer_logo" />
              </Link>

              <p className="footer_location_icons">
                <FaLocationDot />
                {language === "uz"
                  ? footerData.uz.location
                  : footerData.ru.location}
              </p>
              <p className="footer_region_text">
                {language === "uz"
                  ? footerData.uz.region
                  : footerData.ru.region}
              </p>
              <div className="footer_phone_content">
                <p className="footer_phone_icons">
                  <MdPhone />
                  {language === "uz"
                    ? footerData.uz.phone
                    : footerData.ru.phone}
                </p>

                <a href="tel:+998666291000" className="footer_phone_number">
                  +998666291000
                </a>
                <a href="tel:+998666291000" className="footer_phone_number">
                  +998666291000
                </a>
              </div>
            </div>

            <div className="footer_card">
              <p className="footer_card_title">
                {language === "uz" ? footerData.uz.menu : footerData.ru.menu}
              </p>
              <div className="footer_navbar">
                <Link
                  onClick={scrollToTop}
                  to="/"
                  className="footer_navbar_list"
                >
                  {language === "uz" ? footerData.uz.home : footerData.ru.home}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/leaders"
                  className="footer_navbar_list"
                >
                  {language === "uz"
                    ? footerData.uz.doctors
                    : footerData.ru.doctors}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/departments"
                  className="footer_navbar_list"
                >
                  {language === "uz"
                    ? footerData.uz.sections
                    : footerData.ru.sections}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/news"
                  className="footer_navbar_list"
                >
                  {language === "uz" ? footerData.uz.news : footerData.ru.news}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/vacancy"
                  className="footer_navbar_list"
                >
                  {language === "uz"
                    ? footerData.uz.vacancy
                    : footerData.ru.vacancy}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/qvp"
                  className="footer_navbar_list"
                >
                  {language === "uz" ? footerData.uz.qvp : footerData.ru.qvp}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/contact"
                  className="footer_navbar_list"
                >
                  {language === "uz"
                    ? footerData.uz.communication
                    : footerData.ru.communication}
                </Link>
              </div>
            </div>

            <div className="footer_card">
              <a href="https://maps.app.goo.gl/HkU9SaVPeDhzgtBU8">
                <img
                  className="footer_location_img"
                  src={footerLocationImages}
                  alt=""
                />
              </a>
              <a
                className="footer_web_site_link"
                href="/"
              >
                <FaGlobe />
                ishtixonttb.uz
              </a>
              <p className="footer_follow_text">
                {language === "uz"
                  ? footerData.uz.follow
                  : footerData.ru.follow}
              </p>
              <div className="footer_icon_group">
                <a
                  href="https://t.me/IshtixonTTBaxborotxizmati"
                  className="footer_icons_link"
                >
                  <FaTelegram />
                </a>
                <a
                  href="https://www.facebook.com/Ishtixonttb?mibextid=ZbWKwL"
                  className="footer_icons_link"
                >
                  <FaFacebook />
                </a>
                <a
                  href="mailto:aziz.khudayberdiev@ssv.uz"
                  className="footer_icons_link"
                >
                  <MdEmail />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
