import "./Bulimlar.css";
import { Link } from "react-router-dom";
import sectionData from "../../data/section.json";
import cardImages from "../../assets/images/section_card_images.jpg";

const Bulimlar = ({ language }) => {

  return (
    <div className="container">
      <div className="sections">
        <h2 className="sections_title">
          {language === "uz" ? sectionData.uz.title : sectionData.ru.title}
        </h2>

        <div className="sections_content">
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section1
                : sectionData.ru.section1}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section2
                : sectionData.ru.section2}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section3
                : sectionData.ru.section3}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section4
                : sectionData.ru.section4}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section5
                : sectionData.ru.section5}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section6
                : sectionData.ru.section6}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section7
                : sectionData.ru.section7}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section8
                : sectionData.ru.section8}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section9
                : sectionData.ru.section9}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section10
                : sectionData.ru.section10}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section11
                : sectionData.ru.section11}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>

          <div className="sections_card">
            <img
              src={cardImages}
              alt="section images"
              className="sections_card_images"
            />
            <p className="sections_card_text">
              {language === "uz"
                ? sectionData.uz.section12
                : sectionData.ru.section12}
            </p>
            <div className="section_card_hover">
              <Link className="sections_card_link" to="/">
                {language === "uz" ? "Batfsil" : "Подробнее"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bulimlar;
