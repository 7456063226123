import "./Yangiliklar.css";
import card1_1 from "../../assets/images/card1_1.JPG";
import card1_2 from "../../assets/images/card1_2.JPG";
import card1_3 from "../../assets/images/card1_3.JPG";

import card5_1 from "../../assets/images/card_new_5.1.jpg";
import card5_2 from "../../assets/images/card_new_5.2.jpg";
import card5_3 from "../../assets/images/card_new_5.3.jpg";

import card6_1 from "../../assets/images/card_new_6.1.jpg";

import card7_1 from "../../assets/images/card_new_7.1.jpg";
import card8_1 from "../../assets/images/card_new_8.1.jpg";
import card9_1 from "../../assets/images/card_news_9.1.jpg";
import card9_2 from "../../assets/images/card_news_9.2.jpg";
import card9_3 from "../../assets/images/card_news_9.3.jpg";
import card9_4 from "../../assets/images/card_news_9.4.jpg";
import card9_5 from "../../assets/images/card_news_9.5.jpg";
import card9_6 from "../../assets/images/card_news_9.6.jpg";

import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Yangiliklar = ({ language }) => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <div className="container">
      <div className="news">
        <h1 className="news_title">
          {language === "uz" ? "Yangiliklar" : "Новости"}
        </h1>

        <div className="news_content">
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_3}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_4}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_5}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card9_6}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              Бугун Иштихон туман тиббиёт бирлашмасига, Соғлиқни сақлаш вазири
              ўринбосари Баситханова Элмира Эркиновна ва Вилоят соғлиқни сақлаш
              бошқармаси бошлиғи Зайниев Сухроб Собирович ташриф буюриб, Иштихон
              тумани, Пайариқ тумани, Пастдарғом тумани, Оқдарё тумани ва
              Қўшработ тумани масуллари иштирокида ўқув амалий семинари ўтказди.
              Семинардан сўнг КТМП “ Аёллар маслахатхонаси” махаллаларда “Соғлом
              оила хоналари” билан танишиб, патронаж доялар иш фаолиятини
              ўрганиб, савол жавоблар ўтказди. Семинар ажойиб ва кўтаринки
              кайфиятда ўтказилди
            </p>
          </div>

          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card8_1}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              2024 yil 08 iyun kuni soat 08:00 da Ishtixon tuman tibbiyot
              birlashmasi boshlig‘i Xudayberdiyev Aziz Abduganiyevich
              boshchiligida tuman ko‘p tarmoqli markaziy poliklinika tor soha
              mutaxassislari va markaziy shifoxona bo‘lim boshliqlari
              ishtirokida Ishtixon tuman aholisi uchun sog‘lomlashtirishga
              qaratilgan targ‘ibot-tashviqot ishlari va chuqurlashtirilgan
              tibbiy ko‘riklar tashkil etish maqsadida tuman tibbiyot
              birlashmasiga qarashli “Barlos” oilaviy shifokorlik punktida
              sayyor qabul o‘tkazadi. Sizda sog‘ligingizni tiklash borasida
              uchrayotgan muammolar, tibbiyot sohasidagi kamchiliklarga oid
              savollar yoki tizimni rivojlantirish uchun takliflaringiz bo‘lsa,
              belgilangan vaqtda ushbu sayyor qabulda ishtirok etishingiz
              mumkin. O‘zingiz va yaqinlaringiz sog‘lig‘iga befarq bo‘lmang.
              Ishtixon tuman tibbiyot birlashmasi
            </p>
          </div>
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card7_1}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              Assalomu alaykum! 2024 yil 9 may kuni “Aholiga ko‘rsatilayotgan
              birlamchi tibbiy sanitariya yordam holati” bo‘yicha brifing
              o‘tkaziladi. Shu munosabat bilan tumandagi barcha OAVlarni va
              blogerlarni Ishtixon tuman tibbiyot birlashmasining majlislar
              zaliga taklif etamiz. Ishtixon tuman tibbiyot birlashmasi
            </p>
          </div>
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card5_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card5_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card5_3}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              Ishtixon tumani “Qiyot” mahalla fuqarolar yig‘ini “Obod xonadon”,
              “Obod ko‘cha” va “Obod mahalla” mezonlarini joriy etish borasida
              ishlar yakuniga yetdi. Bugun “Zarafshon” bazmgohida ushbu
              mahallaning obodonchilik va qurilish ishlarida faol qatnashgan bir
              guruh tashkilotlar hamda tadbirkorlarga tuman hokimligining
              tashakkurnomalari va esdalik sovg‘alari topshirildi. Tadbir
              davomida bir piyola choy ustida madaniy tadbirlar davom etdi,
              tuman madaniyat bo‘limi xodimlari tomonidan kuy qo‘shiqlar
              barchaning ko‘ngillarini xushnut etdi.
            </p>
          </div>

          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card6_1}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              SAYYOR QABUL - 2024! 2024 yil 11 may kuni soat 08:00 da Ishtixon
              tuman tibbiyot birlashmasi boshlig‘i Xudayberdiyev Aziz
              Abduganiyevich boshchiligida tuman ko‘p tarmoqli markaziy
              poliklinika tor soha mutaxassislari va markaziy shifoxona bo‘lim
              boshliqlari ishtirokida Ishtixon tuman aholisi uchun
              sog‘lomlashtirishga qaratilgan targ‘ibot-tashviqot ishlari va
              chuqurlashtirilgan tibbiy ko‘riklar tashkil etish maqsadida
              tibbiyot birlashmasiga qarashli Ishtixon tumani Baxrin qishlog‘ida
              joylashgan “O‘zbekiston” oilaviy poliklinikasida sayyor qabul
              o‘tkazadi. Sizda sog‘ligingizni tiklash borasida uchrayotgan
              muammolar, tibbiyot sohasidagi kamchiliklarga oid savollar yoki
              tizimni rivojlantirish uchun takliflaringiz bo‘lsa, ushbu sayyor
              qabulda ishtirok etishingiz mumkin. O‘zingiz va yaqinlaringiz
              sog‘lig‘iga befarq bo‘lmang. Ishtixon tuman tibbiyot birlashmasi
            </p>
          </div>

          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_3}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>

            <p className="news_card_text">
              Ishtixon tuman tibbiyot birlashmasiga qarashli Mitan shaharchasida
              joylashgan Mitan oilaviy poliklinikasida Samarqand viloyat ko‘p
              tarmoqli bolalar tibbiyot markazining malakali tor soha
              mutaxassislari terapevt, bolalar nevropatologi, xirurg,
              otorinolaringolog, stomatolog, pediatr, ortoped-travmatologlar
              tomonidan tuman aholisini tibbiy ko‘rikdan o‘tkazishmoqda Ishtixon
              tuman Tibbiyot birlashmasi kanaliga a’zo bo‘ling Telegram 👉🏻
              https://t.me/IshtixonTTBaxborotxizmati Facebook 👉🏻
              https://www.facebook.com/Ishtixonttb?mibextid=ZbWKwL
            </p>
          </div>
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_3}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>

            <p className="news_card_text">
              Ishtixon tuman Tibbiyot birlashmasining majlislar zalida
              birlashmaga qarashli davolash-profilaktika muassasalarida faoliyat
              yuritayotgan “Tibbiy brigada” amaliyotchi hamshiralarining
              O‘zbekiston Respublikasi Sog‘liqni saqlash vazirligining
              №117-sonli buyrug‘i, Sog‘lom turmush tarzi umummilliy loyihasi
              hamda patronaj hamshiralarning lavozim yo‘riqnomasi bo‘yicha o‘quv
              seminari o‘tkazildi va tuman Tibbiyot birlashmasiga qarashli tuman
              ko’p tarmoqli markaziy poliklinika endokrinolog shifokori Otamurod
              Egamov tomonidan qonda glyukozani aniqlash qandli diabetga moil
              aholi orasida amaliyotchi hamshiralarga berilgan Glukomert
              yordamida test o’tkazish usuli o’rgatildi.
            </p>
          </div>
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_3}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>

            <p className="news_card_text">
              Соғлиқни сақлаш вазирлигининг 2023-йил 29-апрелдаги 11-11249-сонли
              кўрсатма хати асосида мамлакатимизда “Ёшлар саломатлиги ойлиги”
              ўтказилмоқда. Шу муносабат билан Самарқанд вилоят Cоғлиқни сақлаш
              бошқармаси томонидан ишлаб чиқилган режага асосан 2023 йил 20-май
              куни Иштихон туман Тиббиёт бирлашмасига қарашли туман кўп тармоқли
              марказий поликлиникада “Ёшлар саломатлиги ойлиги” акцияси
              ўтказилди. Акция доирасида тумандаги 18-30 ёшгача бўлган ёшлар
              вилоятдан келган 15 турдаги тиббиёт мутахассислари ва КТМП тор
              соҳа мутахассислари томонидан мақсадли тиббий кўриклардан
              ўтказилди.
            </p>
          </div>
          <div className="news_card">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_1}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_2}
                  alt="card_images"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="news_card_images"
                  src={card1_3}
                  alt="card_images"
                />
              </SwiperSlide>

              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div>
            </Swiper>
            <p className="news_card_text">
              Ishtixon tuman Tibbiyot birlashmasining majlislar zalida
              birlashmaga qarashli davolash-profilaktika muassasalarida faoliyat
              yuritayotgan “Tibbiy brigada” patronaj hamshiralariga Sog‘lom
              turmush tarzi umummilliy loyihasi, Salomatlik maktabi hamda
              patronaj hamshiralarning lavozim yo‘riqnomasi bo‘yicha o‘quv
              seminari o‘tkazildi. Mazkur o‘quv seminarda yurtdoshlarimizda o‘z
              salomatligiga, to‘g‘ri ovqatlanish tartibiga jiddiy e’tibor
              qaratish mas’uliyatini rivojlantirish borasida, patronaj
              hamshiralarni ish faoliyatida biriktirilgan aholiga, jinsi va
              yoshidan qat’iy nazar birlamchi tibbiy yordam ko‘rsatish va
              karantin, o‘ta xavfli va boshqa yuqumli kasalliklarni oldini olish
              bo‘yicha tadbirlar o‘tkazib borish borasida ma’lumotlar berildi.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yangiliklar;
