import "./About.css";
import aboutImg from "../../assets/images/about.jpg";
import aboutData from "../../data/about.json";
import { FaTelegram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const About = ({ language }) => {
  return (
    <div className="about">
      <div className="container">
        <div className="about_content">
          <img className="about_images" src={aboutImg} alt="about_images" />
          <div className="about_text_content">
            <div className="about_top_text">
              <h2 className="about_title">
                {language === "uz" ? aboutData.uz.about : aboutData.ru.about}
              </h2>
              <p className="about_line"></p>
            </div>
            <p className="about_text">
              {language === "uz"
                ? aboutData.uz.about_text
                : aboutData.ru.about_text}
            </p>
            <div className="about_icons_group">
              <a href="https://t.me/IshtixonTTBaxborotxizmati">
                <FaTelegram className="about_icons" />
              </a>
              <a href="https://www.facebook.com/Ishtixonttb?mibextid=ZbWKwL">
                <FaFacebook className="about_icons" />
              </a>
              <a href="mailto:aziz.khudayberdiev@ssv.uz">
                <MdEmail className="about_icons" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
