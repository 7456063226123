import "./Faqs.css";
import { useState } from "react";
import { FaQuestion } from "react-icons/fa";
const Faqs = ({ language }) => {
  const [faqs1, setFaqs1] = useState(false);
  const [faqs2, setFaqs2] = useState(false);
  const [faqs3, setFaqs3] = useState(false);
  const [faqs4, setFaqs4] = useState(false);
  const [faqs5, setFaqs5] = useState(false);

  return (
    <div className="container">
      <div className="faqs">
        <div className="faqs_top">
          <h2 className="faqs_title">FAQs</h2>
          <p className="faqs_line"></p>
        </div>
        <div className="faqs_content">
          <div onClick={() => setFaqs1(!faqs1)} className="faqs_card">
            <FaQuestion className="faqs_card_icons" />

            <div className="faqs_card_top">
              <p className="faqs_card_text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
                cum veritatis velit, laborum assumenda sed ullam possimus
                consequatur dicta inventore.
              </p>
              {faqs1 && (
                <p className="faqs_click_text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Asperiores, illo.
                </p>
              )}
            </div>
          </div>
          <div onClick={() => setFaqs2(!faqs2)} className="faqs_card">
            <FaQuestion className="faqs_card_icons" />

            <div className="faqs_card_top">
              <p className="faqs_card_text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
                cum veritatis velit, laborum assumenda sed ullam possimus
                consequatur dicta inventore.
              </p>
              {faqs2 && (
                <p className="faqs_click_text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Asperiores, illo.
                </p>
              )}
            </div>
          </div>
          <div onClick={() => setFaqs3(!faqs3)} className="faqs_card">
            <FaQuestion className="faqs_card_icons" />

            <div className="faqs_card_top">
              <p className="faqs_card_text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
                cum veritatis velit, laborum assumenda sed ullam possimus
                consequatur dicta inventore.
              </p>
              {faqs3 && (
                <p className="faqs_click_text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Asperiores, illo.
                </p>
              )}
            </div>
          </div>
          <div onClick={() => setFaqs4(!faqs4)} className="faqs_card">
            <FaQuestion className="faqs_card_icons" />

            <div className="faqs_card_top">
              <p className="faqs_card_text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
                cum veritatis velit, laborum assumenda sed ullam possimus
                consequatur dicta inventore.
              </p>
              {faqs4 && (
                <p className="faqs_click_text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Asperiores, illo.
                </p>
              )}
            </div>
          </div>
          <div onClick={() => setFaqs5(!faqs5)} className="faqs_card">
            <FaQuestion className="faqs_card_icons" />

            <div className="faqs_card_top">
              <p className="faqs_card_text">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
                cum veritatis velit, laborum assumenda sed ullam possimus
                consequatur dicta inventore.
              </p>
              {faqs5 && (
                <p className="faqs_click_text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Asperiores, illo.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
