import "./Qvp.css";
import qvpData from "../../data/qvp.json";
const Qvp = ({ language }) => {
  return (
    <div className="container">
      <div className="qvp">
        <table className="table">
          <thead className="thead">
            <tr className="thead_tr">
              <th className="thead_th">№</th>
              <th className="thead_th">
                {language === "uz" ? "Manzil" : "Адрес"}
              </th>
              <th className="thead_th">
                {language === "uz"
                  ? "Biriktirilgan shifokor"
                  : "Прикрепленный врач"}
              </th>
              <th className="thead_th">
                {language === "uz" ? "Telefon" : "Tелефон"}
              </th>
            </tr>
          </thead>
          <tbody>
            {language === "uz"
              ? qvpData.uz.map((data, index) => (
                  <tr className="data_tr" key={index}>
                    <td className="data_td">{data.id}</td>
                    <td className="data_td">{data.region}</td>
                    <td className="data_td">{data.name}</td>
                    <td className="data_td">{data.phone}</td>
                  </tr>
                ))
              : qvpData.ru.map((data, index) => (
                  <tr className="data_tr" key={index}>
                    <td className="data_td">{data.id}</td>
                    <td className="data_td">{data.region}</td>
                    <td className="data_td">{data.name}</td>
                    <td className="data_td">{data.phone}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Qvp;
