import "./Ish.css";
import workData from "../../data/vacancy.json";
import workCardImg from "../../assets/images/work_card_images1.png";
import { TbClockHour4 } from "react-icons/tb";
const Ish = ({ language }) => {
  return (
    <div className="container">
      <div className="work">
        <h2 className="work_title">
          {language === "uz" ? workData.uz.title : workData.ru.title}
        </h2>
        <div className="work_card">
          <div className="work_card_left">
            <div className="work_card_title_text">
              {language === "uz"
                ? workData.uz.work_text
                : workData.ru.work_text}
              <span className="work_card_title">
                {language === "uz"
                  ? workData.uz.work_name1
                  : workData.ru.work_name1}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.expertise
                : workData.ru.expertise}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.expertise_name1
                  : workData.ru.expertise_name1}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.information
                : workData.ru.information}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.information_name1
                  : workData.ru.information_name1}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.experience
                : workData.ru.experience}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.experience_value1
                  : workData.ru.experience_value1}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz" ? workData.uz.salary : workData.ru.salary}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.salary_value1
                  : workData.ru.salary_value1}
              </span>
            </div>

            <div className="work_card_phone_text">
              <span className="work_card_phone_text">
                {language === "uz" ? workData.uz.phone : workData.ru.phone}
              </span>

              <span className="work_card_phone_text">+998666291000</span>
            </div>
          </div>
          <div className="work_card_right">
            <img className="work_card_images" src={workCardImg} alt="" />
            <p className="work_card_data">
              <TbClockHour4 className="work_card_icons" />
              2024-03-10
            </p>
          </div>
        </div>

        <div className="work_card">
          <div className="work_card_left">
            <div className="work_card_title_text">
              {language === "uz"
                ? workData.uz.work_text
                : workData.ru.work_text}
              <span className="work_card_title">
                {language === "uz"
                  ? workData.uz.work_name2
                  : workData.ru.work_name2}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.expertise
                : workData.ru.expertise}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.expertise_name2
                  : workData.ru.expertise_name2}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.information
                : workData.ru.information}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.information_name2
                  : workData.ru.information_name2}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz"
                ? workData.uz.experience
                : workData.ru.experience}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.experience_value2
                  : workData.ru.experience_value2}
              </span>
            </div>

            <div className="work_card_text">
              {language === "uz" ? workData.uz.salary : workData.ru.salary}

              <span className="work_card_work_text">
                {language === "uz"
                  ? workData.uz.salary_value2
                  : workData.ru.salary_value2}
              </span>
            </div>

            <div className="work_card_phone_text">
              <span className="work_card_phone_text">
                {language === "uz" ? workData.uz.phone : workData.ru.phone}
              </span>

              <span className="work_card_phone_text">+998666291000</span>
            </div>
          </div>
          <div className="work_card_right">
            <img className="work_card_images" src={workCardImg} alt="" />
            <p className="work_card_data">
              <TbClockHour4 className="work_card_icons" />
              2024-03-10
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ish;
