import "./Aloqa.css";
import { FaPhone, FaFacebookF } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

import contactData from "../../data/contact.json";
const Aloqa = ({ language }) => {
  const sendTeligram = async (e) => {
    e.preventDefault();
    let { ism, familiya, tel, izoh } = e.target;
    let chat_id = 792010044;
    let message =
      "Ismi: " +
      ism.value +
      "\nFamiliyasi: " +
      familiya.value +
      "\nTel: +998" +
      tel.value +
      "\nIzoh: " +
      izoh.value;
    await fetch(
      "https://api.telegram.org/bot6717549964:AAHm1LcGwDSqTfZvMkWjwsKEMKYwj5P_sh0/sendMessage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "cache-control": "no-cache",
        },
        body: JSON.stringify({
          chat_id: chat_id,
          text: message,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          alert("Sizning xabaringiz yuborildi!");
          ism.value = "";
          familiya.value = "";
          tel.value = "";
          izoh.value = "";
        } else {
          alert("Server xatolik!");
        }
      });
  };
  return (
    <div className="container">
      <div className="contact">
        <h2 className="contact_title">
          {language === "uz" ? contactData.uz.title : contactData.ru.title}
        </h2>
        <div className="contact_content">
          <div className="contact_left">
            <p className="contact_left_title">
              {language === "uz" ? contactData.uz.text : contactData.ru.text}
            </p>
            <form onSubmit={(e) => sendTeligram(e)} className="contact_form">
              <input
                type="text"
                name="ism"
                placeholder={
                  language === "uz"
                    ? contactData.uz.name_pl
                    : contactData.ru.name_pl
                }
                className="contact_form_input"
                required
                minLength={3}
                maxLength={15}
              />
              <input
                type="text"
                name="familiya"
                className="contact_form_input"
                placeholder={
                  language === "uz"
                    ? contactData.uz.surname_pl
                    : contactData.ru.surname_pl
                }
                required
                minLength={3}
                maxLength={20}
              />
              <input
                name="tel"
                type="tel"
                className="contact_form_input"
                placeholder={
                  language === "uz"
                    ? contactData.uz.tel_pl
                    : contactData.ru.tel_pl
                }
                required
                minLength={9}
                maxLength={13}
              />
              <textarea
                name="izoh"
                className="contact_form_textarea"
                placeholder={
                  language === "uz"
                    ? contactData.uz.textarea_pl
                    : contactData.ru.textarea_pl
                }
                cols="32"
                rows="4"
                required
                maxLength={100}
                minLength={10}
              ></textarea>
              <button type="submit" className="contact_form_btn">
                {language === "uz"
                  ? contactData.uz.form_btn
                  : contactData.ru.form_btn}
              </button>
            </form>
          </div>
          <div className="contact_right">
            <a href="tel:+998666291000" className="contact_link_text">
              <FaPhone className="contact_link_icon" /> +998666291000
            </a>
            <a href="tel:+998666291000" className="contact_link_text">
              <FaPhone className="contact_link_icon" /> +998666291000
            </a>
            <a
              href="https://t.me/IshtixonTTBaxborotxizmati"
              className="contact_link_text"
            >
              <FaTelegramPlane className="contact_link_icon" /> ishtixon_ttb
            </a>
            <a href="mailto:aziz.khudayberdiev@ssv.uz" className="contact_link_text">
              <MdEmail className="contact_link_icon" /> ishtixon_ttb
            </a>
            <a
              href="https://www.facebook.com/Ishtixonttb?mibextid=ZbWKwL"
              className="contact_link_text"
            >
              <FaFacebookF className="contact_link_icon" />
              ishtixon_ttb
            </a>
            <a href="/" className="contact_link_text">
              <IoLocationSharp className="contact_link_icon" />
              {language === "uz"
                ? contactData.uz.region
                : contactData.ru.region}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aloqa;
